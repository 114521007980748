.bg-home {
    background-color: var(--main);
    width: 100%;
    min-height: calc(100vh - 80px);
    position: relative;
    overflow: hidden;
}

.clouds img {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    margin-top: -11.12em;
    height: auto;
    width: 64em;
    overflow: hidden;
}

/* RAIN */
.rain {
    position: absolute;
    display: inline-block;
    top: -3em;
    width: 100%;
    left: 1.5em;
    text-align: center;
    height: 0;
}

.rain i {
    position: relative;
    display: inline-block;
    z-index: 5;
    width: 5px;
    height: 33px;
    margin-right: .7em;
    background: rgba(78, 221, 240, 0.678);
    transform: skew(-20deg);
    box-shadow: inset 0px 0px 1px #222;
    animation: falling 3s 0s infinite;
}

.rain i:nth-of-type(2n+1)    {
    height: 9px; width: 5px;
    animation: falling 4.5s 0s infinite;
}
 
.rain i:nth-of-type(3n+2)  {
     height: 12px; width: 5px; 
     animation: falling 4s 0s infinite;
}

.rain i:nth-of-type(n) { 
    animation-timing-function: ease-in-out;
}

@keyframes falling {
        
    0% {
        -webkit-transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-350px, calc(110vh),0)
            rotate(20deg);
        opacity: .45;
    }
}

.rain i:nth-of-type(n)   { animation-delay: 2.9s;}
.rain i:nth-of-type(2n)  { animation-delay: 4.9s;}
.rain i:nth-of-type(3n)  { animation-delay: 4.3s;}
.rain i:nth-of-type(4n)  { animation-delay: 4.4s;}
.rain i:nth-of-type(5n)  { animation-delay: 5s; }
.rain i:nth-of-type(5n + 2)  { animation-delay: 5.2s;  }
.rain i:nth-of-type(6n)  { animation-delay: 4.5s;}
.rain i:nth-of-type(7n)  { animation-delay: 3.8s;}
.rain i:nth-of-type(8n)  { animation-delay: 4.5s;}
.rain i:nth-of-type(9n)  { animation-delay: 4.3s;}
.rain i:nth-of-type(10n) { animation-delay: 3.5s; }
.rain i:nth-of-type(10n + 1) { animation-delay: 4.2s;}
.rain i:nth-of-type(11n) { animation-delay: 4.2s;}
.rain i:nth-of-type(12n) { animation-delay: 2.1s; }
.rain i:nth-of-type(13n) { animation-delay: 3.5s;  }
.rain i:nth-of-type(14n) { animation-delay: 4.7s; }
.rain i:nth-of-type(16n) { animation-delay: 3s;  }
.rain i:nth-of-type(17n) { animation-delay: 4.5s;  }
.rain i:nth-of-type(18n) { animation-delay: 3.2s;  }
.rain i:nth-of-type(19n) { animation-delay: .4s; }
.rain i:nth-of-type(20n) { animation-delay: 3.8s; }
.rain i:nth-of-type(21n) { animation-delay: 4.1; }


/* END OF RAIN */
.myName {
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: clamp(3rem, 8vw, 5rem); 
    margin: 1em 0 0 0;
	font-weight: bold;
	color: var(--light);
	letter-spacing: 10px;
	text-shadow: 4px 4px 0px var(--main), 6px 6px 0px var(--light-accent);
}


.subHeading {
    position: relative;
    z-index: 8;
    text-align: center;
    font-size: clamp(.95rem, 2.5vh, 1.1rem); 
    color: var(--light);
    letter-spacing: 3px;
    font-weight: 700;
}

.aboutMe div {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    margin-top: 2em;
    max-width: 26em;
    background: #53917e;
    border-radius: 1%;
    padding: 2em;
    box-shadow: inset 0 0 7px var(--light);
}

.aboutMe p {
    position: relative;
    z-index: 10;
    font-size: clamp(1rem, 2.5vw, 1.2rem); 
    line-height: 1.5rem;
    text-align: left;
    color: #ffe0a7;
    text-shadow: 0 0 6px var(--light-accent), 0 0 2px var(--light-accent);
    font-family: Courier, monospace;
    font-smoothing: antialiased;
}

.rainControl {
    display: flex;
    justify-content: center;
}


.rain-btn {
    display: inline-flex;
    position: relative;
    text-align: center;
    font-size: 1.2rem;
    z-index: 10;
    margin: 2em 0 0 1em;
    max-width: 26em;
    background: #53917e;
    border-radius: 1%;
    padding: 1em 1em;
    box-shadow: inset 0 0 7px var(--light);
    color: #ffe0a7;
    text-shadow: 0 0 6px var(--light-accent), 0 0 2px var(--light-accent);
    font-family: Courier, monospace;
}

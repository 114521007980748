.navbar-items {
    background: var(--light-accent);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-inline: auto;
    box-shadow: 0px 10px 10px -15px rgba(0, 0, 0, 0.719);
    z-index: 100;
}

.nav-menu {
    position: relative;
    bottom: -14px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    width: 100%;
    justify-content: center;
}

.nav-menu > li {
    box-sizing: border-box;
    height: var(--menu-item-size);
    width: calc(3.5 * var(--menu-item-size));  
    line-height: var(--menu-item-size);
    background: var(--main);
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 15% 100%, 25% 50%, 15% 0%);
    shape-outside: polygon(10% 0%, 0% 100%, 95% 100%, 100% 50%, 95% 0%);
    transition: all .5s ease;
}

.nav-menu > li:nth-child(1) {
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 25% 100%, 15% 50%, 25% 0%);
    transform: translateX(1.5em);
}

.nav-menu > li:nth-child(2) {
    transform: translateX(-.85em);
}

.nav-menu > li:nth-child(3) {
    transform: translateX(-3.2em)
}

.nav-menu > li:nth-child(4) {
    transform: translateX(-5.55em)
}

.nav-menu > li:nth-child(1):hover {
    margin-right: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-menu > li:nth-child(2):hover,
.nav-menu > li:nth-child(3):hover {
    margin-right: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-menu > li:nth-child(4):hover {
    margin-left: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-links {
    color: var(--light);
    font-weight: 400;
    margin-left: 1.8em;
    padding: 1em 2.5em 1em 2em;
    text-decoration: none;
    font-size: 1.05rem;
}

.nav-links:hover {
    font-weight: 600;
    transition: all .5s ease-out;
}

.active_link {
    font-weight: 900;
}

.fa-bars {
    color: var(--darkPurple)
}

.nav-links-mobile,
.menu-icon,
.nav-links-mobile,
.overlay {
    display: none;
}

@media screen and (max-width: 31.875em) {
    .bg-home {
        min-height: 100vh !important;
    }
    
    .nav-menu > li,
    .nav-menu > li:nth-child(1),
    .nav-menu > li:nth-child(2),
    .nav-menu > li:nth-child(3),
    .nav-menu > li:nth-child(4) {
        all: unset;
        width: 100%;
    }

    .nav-menu > li:nth-child(1) {
        margin-top: -6em;
    }

    .nav-menu > li:nth-child(4):hover {
        margin: unset;
    }

    .nav-menu > li:nth-child(n):hover {
        background: none;
        border: none;
    }

    .nav-links:hover {
        color: white;
    }
    .nav-links {
        font-size: 1.3rem;
        text-align: center;
        padding: 1.2em 0;
        width: 100%;
        font-weight: 500;
        display: table;
        margin: 0;
    }

    .navbar-items {
        background: none;
        position: fixed;
        width: 100%;    
        box-shadow: none;
        z-index: 10000;
    }

    .overlay {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: -100%;
        opacity: 1;
        transition: all .6s ease;
        transition-delay: .5s;
        z-index: 200;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: min(90%, 14em);
        height: 100vh;
        position: absolute;
        top: -21px;
        left: -100%;
        opacity: 1;
        transition: all .6s ease;
    }

    .overlay.active {
        left: 0;
        background-color: rgba(0, 0, 0, 0.658);
        background-size: cover;
        transition: all 0.8s ease;
        transition: all .6s ease;
    }


    .nav-menu.active {
        background: var(--main);
        left: -40px;
        transition: all .8s ease;
        transition-delay: .3s;
    }

    .nav-menu li::before {
        content: none;
    }

    .menu-icon {
        color: var(--light);
        display: block;
        position: absolute;
        left: 0;
        transform: translate(.5em, .22em);
        font-size: 1.8rem;
        cursor: pointer;
        margin-right: .8em;
        z-index: 10;
    }

    .fa-times {
        color: var(--light);
        font-size: 2rem;
    }
}

@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600&family=Oswald:wght@200;300;400;500;600;700&display=swap);
:root {
    --font: 'Josefin Sans', sans-serif;

    --menu-item-size: 45px;
    --font-color: #310d19;
    --dark: #6d1d38;
    --dark-lighter: #973153;
    --main: #53917E;
    --main-darker: #407062;
    --light: #ffdba1;
    --light-accent: #B1B695;
}
  
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Josefin Sans', sans-serif;
    font-family: var(--font);
    font-weight: 400;
    font-size: 1.3125rem;
    line-height: 1.6;
    overflow: overlay;
}

body, h1, h2, h3, p {
    margin: 0;
}

h1, h2 {
    font-weight: 800;
    line-height: 1.3;
}

section {
    padding: 3em 0 2em 0;
}

.nav-links-mobile {
    display: none;
}

.container {
    margin-inline: auto;
    width: min(90%, 60rem);
}

.split {
    display: flex;
    flex-direction: column;
}


/* collapse divs to rows and split divs 50/50 */
@media (min-width: 42.6875em) {
    .split {
        flex-direction: row;
    }

    /* divs fill half of page */
    .split > * {
        flex-basis: 100%;
    }
}

/* Scroll bar */
/* Works on Firefox */
* {
  scrollbar-color: rgb(44, 44, 44) rgba(253, 244, 205, 0.281);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.7em;
  background: rgba(253, 244, 205, 0.281);
}

*::-webkit-scrollbar-thumb {
  background: rgb(44, 44, 44);
  border-radius: 3px;
}






@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.62c81046.eot);
  src:  url(/static/media/icomoon.62c81046.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.22ad37f8.ttf) format('truetype'),
    url(/static/media/icomoon.1ed0836b.woff) format('woff'),
    url(/static/media/icomoon.bf2cf788.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blender:before {
  content: "\e907";
}
.icon-unreal-engine:before {
  content: "\e908";
}
.icon-typescript:before {
  content: "\e906";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-github:before {
  content: "\e904";
}
.icon-aws:before {
  content: "\e905";
}
.icon-database:before {
  content: "\e900";
}
.icon-javascript:before {
  content: "\e901";
}
.icon-react:before {
  content: "\e902";
}

.navbar-items {
    background: var(--light-accent);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-inline: auto;
    box-shadow: 0px 10px 10px -15px rgba(0, 0, 0, 0.719);
    z-index: 100;
}

.nav-menu {
    position: relative;
    bottom: -14px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    width: 100%;
    justify-content: center;
}

.nav-menu > li {
    box-sizing: border-box;
    height: var(--menu-item-size);
    width: calc(3.5 * var(--menu-item-size));  
    line-height: var(--menu-item-size);
    background: var(--main);
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 15% 100%, 25% 50%, 15% 0%);
            clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 15% 100%, 25% 50%, 15% 0%);
    shape-outside: polygon(10% 0%, 0% 100%, 95% 100%, 100% 50%, 95% 0%);
    transition: all .5s ease;
}

.nav-menu > li:nth-child(1) {
    -webkit-clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 25% 100%, 15% 50%, 25% 0%);
            clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 25% 100%, 15% 50%, 25% 0%);
    -webkit-transform: translateX(1.5em);
            transform: translateX(1.5em);
}

.nav-menu > li:nth-child(2) {
    -webkit-transform: translateX(-.85em);
            transform: translateX(-.85em);
}

.nav-menu > li:nth-child(3) {
    -webkit-transform: translateX(-3.2em);
            transform: translateX(-3.2em)
}

.nav-menu > li:nth-child(4) {
    -webkit-transform: translateX(-5.55em);
            transform: translateX(-5.55em)
}

.nav-menu > li:nth-child(1):hover {
    margin-right: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-menu > li:nth-child(2):hover,
.nav-menu > li:nth-child(3):hover {
    margin-right: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-menu > li:nth-child(4):hover {
    margin-left: .5em;
    background-color: var(--main-darker);
    transition: all .5s ease-out;
}

.nav-links {
    color: var(--light);
    font-weight: 400;
    margin-left: 1.8em;
    padding: 1em 2.5em 1em 2em;
    text-decoration: none;
    font-size: 1.05rem;
}

.nav-links:hover {
    font-weight: 600;
    transition: all .5s ease-out;
}

.active_link {
    font-weight: 900;
}

.fa-bars {
    color: var(--darkPurple)
}

.nav-links-mobile,
.menu-icon,
.nav-links-mobile,
.overlay {
    display: none;
}

@media screen and (max-width: 31.875em) {
    .bg-home {
        min-height: 100vh !important;
    }
    
    .nav-menu > li,
    .nav-menu > li:nth-child(1),
    .nav-menu > li:nth-child(2),
    .nav-menu > li:nth-child(3),
    .nav-menu > li:nth-child(4) {
        all: unset;
        width: 100%;
    }

    .nav-menu > li:nth-child(1) {
        margin-top: -6em;
    }

    .nav-menu > li:nth-child(4):hover {
        margin: unset;
    }

    .nav-menu > li:nth-child(n):hover {
        background: none;
        border: none;
    }

    .nav-links:hover {
        color: white;
    }
    .nav-links {
        font-size: 1.3rem;
        text-align: center;
        padding: 1.2em 0;
        width: 100%;
        font-weight: 500;
        display: table;
        margin: 0;
    }

    .navbar-items {
        background: none;
        position: fixed;
        width: 100%;    
        box-shadow: none;
        z-index: 10000;
    }

    .overlay {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: -100%;
        opacity: 1;
        transition: all .6s ease;
        transition-delay: .5s;
        z-index: 200;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: min(90%, 14em);
        height: 100vh;
        position: absolute;
        top: -21px;
        left: -100%;
        opacity: 1;
        transition: all .6s ease;
    }

    .overlay.active {
        left: 0;
        background-color: rgba(0, 0, 0, 0.658);
        background-size: cover;
        transition: all 0.8s ease;
        transition: all .6s ease;
    }


    .nav-menu.active {
        background: var(--main);
        left: -40px;
        transition: all .8s ease;
        transition-delay: .3s;
    }

    .nav-menu li::before {
        content: none;
    }

    .menu-icon {
        color: var(--light);
        display: block;
        position: absolute;
        left: 0;
        -webkit-transform: translate(.5em, .22em);
                transform: translate(.5em, .22em);
        font-size: 1.8rem;
        cursor: pointer;
        margin-right: .8em;
        z-index: 10;
    }

    .fa-times {
        color: var(--light);
        font-size: 2rem;
    }
}

.bg-home {
    background-color: var(--main);
    width: 100%;
    min-height: calc(100vh - 80px);
    position: relative;
    overflow: hidden;
}

.clouds img {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 100;
    margin-top: -11.12em;
    height: auto;
    width: 64em;
    overflow: hidden;
}

/* RAIN */
.rain {
    position: absolute;
    display: inline-block;
    top: -3em;
    width: 100%;
    left: 1.5em;
    text-align: center;
    height: 0;
}

.rain i {
    position: relative;
    display: inline-block;
    z-index: 5;
    width: 5px;
    height: 33px;
    margin-right: .7em;
    background: rgba(78, 221, 240, 0.678);
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
    box-shadow: inset 0px 0px 1px #222;
    -webkit-animation: falling 3s 0s infinite;
            animation: falling 3s 0s infinite;
}

.rain i:nth-of-type(2n+1)    {
    height: 9px; width: 5px;
    -webkit-animation: falling 4.5s 0s infinite;
            animation: falling 4.5s 0s infinite;
}
 
.rain i:nth-of-type(3n+2)  {
     height: 12px; width: 5px; 
     -webkit-animation: falling 4s 0s infinite; 
             animation: falling 4s 0s infinite;
}

.rain i:nth-of-type(n) { 
    -webkit-animation-timing-function: ease-in-out; 
            animation-timing-function: ease-in-out;
}

@-webkit-keyframes falling {
        
    0% {
        -webkit-transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-350px, calc(110vh),0)
            rotate(20deg);
        opacity: .45;
    }
}

@keyframes falling {
        
    0% {
        -webkit-transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-350px, calc(110vh),0)
            rotate(20deg);
        opacity: .45;
    }
}

.rain i:nth-of-type(n)   { -webkit-animation-delay: 2.9s; animation-delay: 2.9s;}
.rain i:nth-of-type(2n)  { -webkit-animation-delay: 4.9s; animation-delay: 4.9s;}
.rain i:nth-of-type(3n)  { -webkit-animation-delay: 4.3s; animation-delay: 4.3s;}
.rain i:nth-of-type(4n)  { -webkit-animation-delay: 4.4s; animation-delay: 4.4s;}
.rain i:nth-of-type(5n)  { -webkit-animation-delay: 5s; animation-delay: 5s; }
.rain i:nth-of-type(5n + 2)  { -webkit-animation-delay: 5.2s; animation-delay: 5.2s;  }
.rain i:nth-of-type(6n)  { -webkit-animation-delay: 4.5s; animation-delay: 4.5s;}
.rain i:nth-of-type(7n)  { -webkit-animation-delay: 3.8s; animation-delay: 3.8s;}
.rain i:nth-of-type(8n)  { -webkit-animation-delay: 4.5s; animation-delay: 4.5s;}
.rain i:nth-of-type(9n)  { -webkit-animation-delay: 4.3s; animation-delay: 4.3s;}
.rain i:nth-of-type(10n) { -webkit-animation-delay: 3.5s; animation-delay: 3.5s; }
.rain i:nth-of-type(10n + 1) { -webkit-animation-delay: 4.2s; animation-delay: 4.2s;}
.rain i:nth-of-type(11n) { -webkit-animation-delay: 4.2s; animation-delay: 4.2s;}
.rain i:nth-of-type(12n) { -webkit-animation-delay: 2.1s; animation-delay: 2.1s; }
.rain i:nth-of-type(13n) { -webkit-animation-delay: 3.5s; animation-delay: 3.5s;  }
.rain i:nth-of-type(14n) { -webkit-animation-delay: 4.7s; animation-delay: 4.7s; }
.rain i:nth-of-type(16n) { -webkit-animation-delay: 3s; animation-delay: 3s;  }
.rain i:nth-of-type(17n) { -webkit-animation-delay: 4.5s; animation-delay: 4.5s;  }
.rain i:nth-of-type(18n) { -webkit-animation-delay: 3.2s; animation-delay: 3.2s;  }
.rain i:nth-of-type(19n) { -webkit-animation-delay: .4s; animation-delay: .4s; }
.rain i:nth-of-type(20n) { -webkit-animation-delay: 3.8s; animation-delay: 3.8s; }
.rain i:nth-of-type(21n) { -webkit-animation-delay: 4.1; animation-delay: 4.1; }


/* END OF RAIN */
.myName {
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: clamp(3rem, 8vw, 5rem); 
    margin: 1em 0 0 0;
	font-weight: bold;
	color: var(--light);
	letter-spacing: 10px;
	text-shadow: 4px 4px 0px var(--main), 6px 6px 0px var(--light-accent);
}


.subHeading {
    position: relative;
    z-index: 8;
    text-align: center;
    font-size: clamp(.95rem, 2.5vh, 1.1rem); 
    color: var(--light);
    letter-spacing: 3px;
    font-weight: 700;
}

.aboutMe div {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    margin-top: 2em;
    max-width: 26em;
    background: #53917e;
    border-radius: 1%;
    padding: 2em;
    box-shadow: inset 0 0 7px var(--light);
}

.aboutMe p {
    position: relative;
    z-index: 10;
    font-size: clamp(1rem, 2.5vw, 1.2rem); 
    line-height: 1.5rem;
    text-align: left;
    color: #ffe0a7;
    text-shadow: 0 0 6px var(--light-accent), 0 0 2px var(--light-accent);
    font-family: Courier, monospace;
    font-smoothing: antialiased;
}

.rainControl {
    display: flex;
    justify-content: center;
}


.rain-btn {
    display: inline-flex;
    position: relative;
    text-align: center;
    font-size: 1.2rem;
    z-index: 10;
    margin: 2em 0 0 1em;
    max-width: 26em;
    background: #53917e;
    border-radius: 1%;
    padding: 1em 1em;
    box-shadow: inset 0 0 7px var(--light);
    color: #ffe0a7;
    text-shadow: 0 0 6px var(--light-accent), 0 0 2px var(--light-accent);
    font-family: Courier, monospace;
}

.section1 {
    background: var(--dark-lighter);
}

.container.sec1 {
    width: min(95%, 73rem);
}

.split.sec1-01 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 9em;
}

.technology_h1 {
    letter-spacing: 4px;
    margin-top: 3em;
    margin-bottom: 1em;
    text-align: center;
    font-size: clamp(3rem, 10vw, 5rem); 
    line-height: 1.5rem;
    color: #ffe0a7;
    font-smoothing: antialiased;
    text-shadow: 4px 4px 0px var(--dark-lighter), 5px 5px 0px var(--light-accent);
}

.tech_card h1 {
    position: relative;
    z-index: 0;
    text-align: center;
    font-size: clamp(1.2rem, 2.5vw, 1.8rem); 
    font-weight: 500;
    margin-bottom: .5em;
}

.tech_card:first-of-type {
    margin-bottom: 1.5em;
}

.tech_card {
    font-size: clamp(1rem, 2.5vh, 1.35rem); 
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 24em;
    border-radius: 1%;
    padding: 1.5em 1.5em 2em 1.5em;
    background-color: var(--dark-lighter);
    box-shadow: inset 0 0 7px var(--light);
    margin: 0 auto;
    color: #ffe0a7;
}

.tech-icons {
    text-align: center;
    margin-bottom: .3em;
}

.tech-icons .font-icons {
    margin-right: .15em;
}

.tech-icons .font-icons.icon-javascript,
.tech-icons .font-icons.icon-typescript {
    margin-left: .5em;
}

.tech_p {
    margin-top: .5em;
    font-size: clamp(1rem, 2.5vh, 1.35rem); 
    line-height: 1.5em;
    text-shadow: 0 0 1px var(--light-accent), 0 0 1px var(--light-accent);
    font-smoothing: antialiased;
    font-weight: 300;
}

.tech_p span {
    font-family: Courier, monospace;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.178);
}

@media (min-width: 42.6875em) {
    .tech_card:first-of-type {
        margin-right: 1em;
    }

    .tech_card:first-of-type {
        margin-bottom: 0;
    }
}
.section2 {
    background: var(--dark);
}

.container.sec2 {
    width: min(90%, 76rem);
}

/* .split.sec2-01 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 7em;
} */

.work_h1 {
    position: relative;
    z-index: 0;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
    font-size: clamp(3rem, 10vw, 5rem); 
    line-height: 1.5rem;
    color: #ffe0a7;
    font-smoothing: antialiased;
    text-shadow: 4px 4px 0px var(--dark), 5px 5px 0px var(--light-accent);
}

.project_card {
    position: relative;
    z-index: 1;
    width: min(90%, 40em); 
    border-radius: 1%;
    /* padding: 1.5em 1.5em 2em 1.5em; */
    box-shadow: inset 0 0 7px var(--light);
    margin: 0 auto;
    background: var(--main);
    margin-bottom: 12em;
}

.project_card:last-of-type {
    margin-bottom: 6em;
}

.project_title {
    display: flex;
    align-items: center;
    color: var(--light);
    border-bottom: 1px solid var(--light);
    padding: 1.25em 1.5em .3em 1.5em;
    overflow-wrap: break-word;
    /* border shadow on bottom only */
    box-shadow: 0px 5px 5px -5px var(--light); 
}

.project_name {
    font-size: clamp(1.5rem, 4vw, 2rem); 
    display: flex;   
    flex-grow: 1; 
}

.project_year {
    font-weight: 600;
    font-size: 1.5rem;
}

.icons {
    margin: .2em 0 .5em 0;
    overflow-wrap: break-word;
    text-align: center;
}

.font-icons.icon-aws {
    position: relative;
    top: 5px;
}

.hosting strong {
    margin-right: .2em;
}

.font-icons {
    margin-left: .5em;
    font-size: 2rem;
}

.icons strong {
    font-size: 1.2rem;
    margin-left: .35em
}

.icons strong:nth-of-type(2) {
    margin-right: .8em;
}

.font-icons:last-of-type {
    margin-left: 0;
}

.font-icons:first-of-type {
    margin-left: 0;
}

.project_description {
    font-size: clamp(1.1rem, 4vw, 1.25rem); 
    color: var(--light);
    font-weight: 400;
    padding: 1.25em 1.5em 4em 1.7em;
    overflow-y: scroll;
    max-height: 18em;
}

.project_description p {
    line-height: 30px;
}

.features_ul {
    margin-top: .5em;
    margin-bottom: 0;
}

.bottom-btns {
    height: 0;
    display: flex;
    width: 100%;
}

.left {
    display: flex;
    flex-grow: 1;
    -webkit-transform: translate(-20px, -45px);
            transform: translate(-20px, -45px);
}

.left img {
    background-color: white;
    border-radius: 5%;
    padding: .7em .75em .7em .75em;
    box-shadow: 3px 2px 9px 1px rgba(0, 0, 0, 0.13);
    width: min(40vw, 9em);
    height: min(22vw, 4.5em);
    position: absolute;
    transition: all .35s ease-in-out;
}

.left a:first-of-type img {
    padding: 1.2em 1.25em 1.2em 1.25em;
    opacity: 0.9;
}

.left a:last-of-type img:hover {
    opacity: 0.0;
}

.left.client img {
    padding: .7em .75em .7em .75em;
    opacity: unset;
}
.left.client img:hover {
    opacity: .9;
}


.right {
    display: flex;
    -webkit-transform: translate(20px, -45px);
            transform: translate(20px, -45px);
}

.right a {
    text-decoration: none;
}

.right h3 {
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 97, 97);
    border-radius: 5%;
    padding-top: .5em;
    box-shadow: 3px 2px 9px 1px rgba(0, 0, 0, 0.13);
    width: min(40vw, 4.5em);
    height: min(22.3vw, 2.3em);
    position: relative;
    transition: all .35s ease-in-out;
}

.right h3:hover {
    opacity: .9;

}
.section3 {
    background: var(--main);
    height: 18.5em;
}

.container.sec3 {
    width: min(90%, 65rem);
    text-align: center;
    color: var(--light);
}

.container.sec3 h1 {
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: clamp(2rem, 10vw, 3.5rem); 
	font-weight: bold;
	color: var(--light);
	letter-spacing: 3px;
}

.container.sec3 a:first-of-type {
    margin-right: 1em;
}

.container.sec3 a {
    background-color: var(--light);
    padding: .35em .6em .25em .6em;
    border-radius: 3%;
    color: var(--main);
    font-size: clamp(1.3rem, 2.8vw, 1.5rem); 
    text-decoration: none;
    transition: all .3s ease;
}

.container.sec3 p {
    margin: 0 0 .7em 0;
    font-weight: 300;
    font-size: clamp(1.3rem, 2.8vw, 1.75rem); 
}

.container.sec3 a:hover {
    font-size: 1.6rem;
    font-weight: 600;
}

.container.sec3 div:first-child {
    margin: 0 auto;
    max-width: 25em;
    background: #53917e;
    border-radius: 1%;
    padding: 2em 1em 2em 1em;
    box-shadow: inset 0 0 7px var(--light);
}

.contact_btns {
    display: flex;
    margin: 0 auto;
    max-width: 70%;
    justify-content: space-evenly;
}

.icon-github,
.icon-linkedin {
    position: relative;
    font-size: 2rem;
    top: 5px;
    margin-right: .05em;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600&family=Oswald:wght@200;300;400;500;600;700&display=swap');

:root {
    --font: 'Josefin Sans', sans-serif;

    --menu-item-size: 45px;
    --font-color: #310d19;
    --dark: #6d1d38;
    --dark-lighter: #973153;
    --main: #53917E;
    --main-darker: #407062;
    --light: #ffdba1;
    --light-accent: #B1B695;
}
  
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: var(--font);
    font-weight: 400;
    font-size: 1.3125rem;
    line-height: 1.6;
    overflow: overlay;
}

body, h1, h2, h3, p {
    margin: 0;
}

h1, h2 {
    font-weight: 800;
    line-height: 1.3;
}

section {
    padding: 3em 0 2em 0;
}

.nav-links-mobile {
    display: none;
}

.container {
    margin-inline: auto;
    width: min(90%, 60rem);
}

.split {
    display: flex;
    flex-direction: column;
}


/* collapse divs to rows and split divs 50/50 */
@media (min-width: 42.6875em) {
    .split {
        flex-direction: row;
    }

    /* divs fill half of page */
    .split > * {
        flex-basis: 100%;
    }
}

/* Scroll bar */
/* Works on Firefox */
* {
  scrollbar-color: rgb(44, 44, 44) rgba(253, 244, 205, 0.281);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.7em;
  background: rgba(253, 244, 205, 0.281);
}

*::-webkit-scrollbar-thumb {
  background: rgb(44, 44, 44);
  border-radius: 3px;
}






@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?iip60h');
  src:  url('fonts/icomoon.eot?iip60h#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?iip60h') format('truetype'),
    url('fonts/icomoon.woff?iip60h') format('woff'),
    url('fonts/icomoon.svg?iip60h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blender:before {
  content: "\e907";
}
.icon-unreal-engine:before {
  content: "\e908";
}
.icon-typescript:before {
  content: "\e906";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-github:before {
  content: "\e904";
}
.icon-aws:before {
  content: "\e905";
}
.icon-database:before {
  content: "\e900";
}
.icon-javascript:before {
  content: "\e901";
}
.icon-react:before {
  content: "\e902";
}

.section1 {
    background: var(--dark-lighter);
}

.container.sec1 {
    width: min(95%, 73rem);
}

.split.sec1-01 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 9em;
}

.technology_h1 {
    letter-spacing: 4px;
    margin-top: 3em;
    margin-bottom: 1em;
    text-align: center;
    font-size: clamp(3rem, 10vw, 5rem); 
    line-height: 1.5rem;
    color: #ffe0a7;
    font-smoothing: antialiased;
    text-shadow: 4px 4px 0px var(--dark-lighter), 5px 5px 0px var(--light-accent);
}

.tech_card h1 {
    position: relative;
    z-index: 0;
    text-align: center;
    font-size: clamp(1.2rem, 2.5vw, 1.8rem); 
    font-weight: 500;
    margin-bottom: .5em;
}

.tech_card:first-of-type {
    margin-bottom: 1.5em;
}

.tech_card {
    font-size: clamp(1rem, 2.5vh, 1.35rem); 
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 24em;
    border-radius: 1%;
    padding: 1.5em 1.5em 2em 1.5em;
    background-color: var(--dark-lighter);
    box-shadow: inset 0 0 7px var(--light);
    margin: 0 auto;
    color: #ffe0a7;
}

.tech-icons {
    text-align: center;
    margin-bottom: .3em;
}

.tech-icons .font-icons {
    margin-right: .15em;
}

.tech-icons .font-icons.icon-javascript,
.tech-icons .font-icons.icon-typescript {
    margin-left: .5em;
}

.tech_p {
    margin-top: .5em;
    font-size: clamp(1rem, 2.5vh, 1.35rem); 
    line-height: 1.5em;
    text-shadow: 0 0 1px var(--light-accent), 0 0 1px var(--light-accent);
    font-smoothing: antialiased;
    font-weight: 300;
}

.tech_p span {
    font-family: Courier, monospace;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.178);
}

@media (min-width: 42.6875em) {
    .tech_card:first-of-type {
        margin-right: 1em;
    }

    .tech_card:first-of-type {
        margin-bottom: 0;
    }
}
.section3 {
    background: var(--main);
    height: 18.5em;
}

.container.sec3 {
    width: min(90%, 65rem);
    text-align: center;
    color: var(--light);
}

.container.sec3 h1 {
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: clamp(2rem, 10vw, 3.5rem); 
	font-weight: bold;
	color: var(--light);
	letter-spacing: 3px;
}

.container.sec3 a:first-of-type {
    margin-right: 1em;
}

.container.sec3 a {
    background-color: var(--light);
    padding: .35em .6em .25em .6em;
    border-radius: 3%;
    color: var(--main);
    font-size: clamp(1.3rem, 2.8vw, 1.5rem); 
    text-decoration: none;
    transition: all .3s ease;
}

.container.sec3 p {
    margin: 0 0 .7em 0;
    font-weight: 300;
    font-size: clamp(1.3rem, 2.8vw, 1.75rem); 
}

.container.sec3 a:hover {
    font-size: 1.6rem;
    font-weight: 600;
}

.container.sec3 div:first-child {
    margin: 0 auto;
    max-width: 25em;
    background: #53917e;
    border-radius: 1%;
    padding: 2em 1em 2em 1em;
    box-shadow: inset 0 0 7px var(--light);
}

.contact_btns {
    display: flex;
    margin: 0 auto;
    max-width: 70%;
    justify-content: space-evenly;
}

.icon-github,
.icon-linkedin {
    position: relative;
    font-size: 2rem;
    top: 5px;
    margin-right: .05em;
}
.section2 {
    background: var(--dark);
}

.container.sec2 {
    width: min(90%, 76rem);
}

/* .split.sec2-01 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 7em;
} */

.work_h1 {
    position: relative;
    z-index: 0;
    letter-spacing: 4px;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
    font-size: clamp(3rem, 10vw, 5rem); 
    line-height: 1.5rem;
    color: #ffe0a7;
    font-smoothing: antialiased;
    text-shadow: 4px 4px 0px var(--dark), 5px 5px 0px var(--light-accent);
}

.project_card {
    position: relative;
    z-index: 1;
    width: min(90%, 40em); 
    border-radius: 1%;
    /* padding: 1.5em 1.5em 2em 1.5em; */
    box-shadow: inset 0 0 7px var(--light);
    margin: 0 auto;
    background: var(--main);
    margin-bottom: 12em;
}

.project_card:last-of-type {
    margin-bottom: 6em;
}

.project_title {
    display: flex;
    align-items: center;
    color: var(--light);
    border-bottom: 1px solid var(--light);
    padding: 1.25em 1.5em .3em 1.5em;
    overflow-wrap: break-word;
    /* border shadow on bottom only */
    box-shadow: 0px 5px 5px -5px var(--light); 
}

.project_name {
    font-size: clamp(1.5rem, 4vw, 2rem); 
    display: flex;   
    flex-grow: 1; 
}

.project_year {
    font-weight: 600;
    font-size: 1.5rem;
}

.icons {
    margin: .2em 0 .5em 0;
    overflow-wrap: break-word;
    text-align: center;
}

.font-icons.icon-aws {
    position: relative;
    top: 5px;
}

.hosting strong {
    margin-right: .2em;
}

.font-icons {
    margin-left: .5em;
    font-size: 2rem;
}

.icons strong {
    font-size: 1.2rem;
    margin-left: .35em
}

.icons strong:nth-of-type(2) {
    margin-right: .8em;
}

.font-icons:last-of-type {
    margin-left: 0;
}

.font-icons:first-of-type {
    margin-left: 0;
}

.project_description {
    font-size: clamp(1.1rem, 4vw, 1.25rem); 
    color: var(--light);
    font-weight: 400;
    padding: 1.25em 1.5em 4em 1.7em;
    overflow-y: scroll;
    max-height: 18em;
}

.project_description p {
    line-height: 30px;
}

.features_ul {
    margin-top: .5em;
    margin-bottom: 0;
}

.bottom-btns {
    height: 0;
    display: flex;
    width: 100%;
}

.left {
    display: flex;
    flex-grow: 1;
    transform: translate(-20px, -45px);
}

.left img {
    background-color: white;
    border-radius: 5%;
    padding: .7em .75em .7em .75em;
    box-shadow: 3px 2px 9px 1px rgba(0, 0, 0, 0.13);
    width: min(40vw, 9em);
    height: min(22vw, 4.5em);
    position: absolute;
    transition: all .35s ease-in-out;
}

.left a:first-of-type img {
    padding: 1.2em 1.25em 1.2em 1.25em;
    opacity: 0.9;
}

.left a:last-of-type img:hover {
    opacity: 0.0;
}

.left.client img {
    padding: .7em .75em .7em .75em;
    opacity: unset;
}
.left.client img:hover {
    opacity: .9;
}


.right {
    display: flex;
    transform: translate(20px, -45px);
}

.right a {
    text-decoration: none;
}

.right h3 {
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 97, 97);
    border-radius: 5%;
    padding-top: .5em;
    box-shadow: 3px 2px 9px 1px rgba(0, 0, 0, 0.13);
    width: min(40vw, 4.5em);
    height: min(22.3vw, 2.3em);
    position: relative;
    transition: all .35s ease-in-out;
}

.right h3:hover {
    opacity: .9;

}